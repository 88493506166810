import React from 'react';
import { PortableText } from '@portabletext/react';
import { Link } from 'gatsby';
import { trackEvent } from '@/utils/amplitude-tracking';
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics';
import CtaButton from '../CtaButton';

const CTALink = ({ link, className }) => {
  if (link._type === 'internalLink') {
    // trim the slash from the beginning of the slug if there is one
    const slug = link.slug.current.startsWith('/')
      ? link.slug.current.slice(1)
      : link.slug.current;

    return (
      <Link
        onClick={() => trackEvent(link)}
        to={`/${slug}`}
        className={className}
      >
        {link.label}
      </Link>
    );
  }

  if (link._type === 'externalLink') {
    return (
      <OutboundLink
        href={link.url}
        onClick={() => trackEvent(link)}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {link.label}
      </OutboundLink>
    );
  }
};

const CaseStudySummary = ({ data }) => {
  const title = data?.head?.title || '';
  const logos = data?.head?.productLogos || '';

  return (
    <div className="h-fit w-full grow-0 rounded-lg border border-black/20 bg-white p-4 md:mt-5 md:w-1/4 lg:w-1/5 lg:px-6 lg:py-8">
      {/* head */}
      <h4 className="text-lg text-[#4B5563] md:text-xl">{title}</h4>
      <div className="my-4 flex flex-col items-start gap-2">
        {logos.length
          ? logos.map((logo) => (
              <img src={logo.asset.url} alt={logo.alt} className="h-6 w-auto" />
            ))
          : null}
      </div>

      {/* items */}
      <div className="my-4 flex flex-col gap-4 border-b border-t border-[#cdcdcd] py-4 lg:my-8 lg:gap-8 lg:py-8">
        {data?.items?.map((item) => (
          <div className="flex flex-col gap-2">
            <h3 className="text-3xl font-medium leading-tight text-smoky-black md:text-4xl">
              {item.title}
            </h3>
            <p className="text-base text-[#4B5563]">
              <PortableText value={item._rawText} />
            </p>
          </div>
        ))}
      </div>

      {/* CTA */}
      <h4 className="text-lg text-[#4B5563] md:text-xl">{data?.ctaLabel}</h4>
      {data?.ctaLink[0] && (
        <div className="mb-4 mt-6">
          <CtaButton
            className="w-fit"
            type="small"
            variant="gradient"
            link={data.ctaLink[0]}
          />
        </div>
      )}
    </div>
  );
};

export default CaseStudySummary;
