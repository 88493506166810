import React from 'react';
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics';
import { Link } from 'gatsby';
import { PortableText } from '@portabletext/react';
import { trackEvent } from '@/utils/amplitude-tracking';
import CtaButton from '../CtaButton';

export const portableTextHeading = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children, value }) => (
      <span className="title-hl">{children}</span>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const CTALink = ({ link, className }) => {
  if (link._type === 'internalLink') {
    // trim the slash from the beginning of the slug if there is one
    const slug = link.slug.current.startsWith('/')
      ? link.slug.current.slice(1)
      : link.slug.current;

    return (
      <Link
        onClick={() => trackEvent(link)}
        to={`/${slug}`}
        className={className}
      >
        {link.label}
      </Link>
    );
  }

  if (link._type === 'externalLink') {
    return (
      <OutboundLink
        href={link.url}
        onClick={() => trackEvent(link)}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {link.label}
      </OutboundLink>
    );
  }
};

const CTAButtons = ({ title, description, primaryBtn, secondaryBtn }) => {
  return (
    <div className="relative z-10 h-fit w-full overflow-hidden rounded-xl bg-smoky-black p-8 md:mb-14 md:px-12 md:py-24">
      {/* background */}
      <div className="absolute -bottom-[40%] left-[25%] -z-10 h-2/3 w-2/3 bg-gradient-hero blur-[9rem]" />

      <div className="flex w-full flex-col items-center gap-4">
        <h3 className="text-center text-3xl font-medium leading-tight text-white md:w-[50%] md:text-4xl">
          {title}
        </h3>
        {description && (
          <div className="my-4 w-full border-t border-t-white/30 pt-6 text-white">
            <p className="mx-auto w-full text-center text-base font-normal text-white/80 md:w-1/2 lg:w-1/3">
              <PortableText
                value={description}
                components={portableTextHeading as any}
              />
            </p>
          </div>
        )}
        <div className="flex w-full flex-col flex-nowrap items-center justify-center gap-4 pt-2 md:w-fit md:flex-row md:gap-10">
          <CTALink
            link={primaryBtn}
            className="w-full min-w-52 flex-1 whitespace-nowrap rounded-lg bg-white px-6 py-3 text-center text-base font-semibold text-primary-600 hover:opacity-80 md:w-fit"
          />
          {secondaryBtn && (
            <CTALink
              link={secondaryBtn}
              className="btn-gradient-border w-full min-w-52 flex-1 whitespace-nowrap !px-6 !py-3 text-center md:w-fit"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const CTAButtonsV2 = ({ primaryBtn, title }) => {
  return (
    <div className="md:16 relative z-10 h-fit w-full overflow-hidden rounded-xl bg-white p-8 md:mb-14 md:py-24 lg:px-24">
      {/* background */}
      <div className="absolute left-[60%] top-full -z-10 h-[748.693px] w-[984.984px] rotate-[76.065deg] bg-[linear-gradient(0deg,rgba(105,94,255,0.20)_0%,rgba(105,94,255,0.20)_100%)] blur-[100px]" />
      <div className="absolute -left-[25%] bottom-0 -z-10 h-[496.159px] w-[552px] shrink-0 rotate-[15deg] bg-[linear-gradient(0deg,rgba(255,175,39,0.15)_0%,rgba(255,175,39,0.15)_100%)] blur-[100px]" />

      <div className="flex flex-col items-center justify-between gap-8 md:flex-row">
        <h3 className="text-center text-3xl font-medium leading-tight text-black md:text-4xl">
          {title}
        </h3>
        <CtaButton type="regular" link={primaryBtn} variant="gradient" />
      </div>
    </div>
  );
};

export default CTAButtonsV2;
